<template>
  <div>
    <!--     v-if="hasMaintainMode"-->
    <div v-if="false">
      <div class="mb-2 warning-message">
        <div>
          <h5 class="mb-2 title">
            {{ $t('home.maintenance.titleMaintenance') }}
          </h5>
          <p>{{ $t('home.maintenance.textHiThere') }} <span style="width: 30px">🙌</span></p>
          <p>{{ $t('home.maintenance.firstContent') }}</p>
          <p>{{ $t('home.maintenance.secondContent') }}</p>
          <p>{{ $t('home.maintenance.finalContent') }}</p>
        </div>
      </div>
    </div>

    <banner-header
      v-if="isFromTikTok"
      :img="require('@/assets/images/banner/banner-tiktok-app-center.png')"
    />

    <banner-affiliate-program
      v-else-if="isEcomdyPlatform && !isPartner && !isExclusivePartner && !userInfoLoading"
      class="mb-2"
    />

    <div
      v-if="showAdsBlockNotice"
    >
      <ads-blocked-notice />
    </div>

    <div v-else-if="!userInfoLoading">

      <summary-balance
        :has-referral-tracking="hasReferralTracking"
        :spent-today="spentToday"
        :total-spending="totalSpending"
        :user-report-loading="userReportLoading"
        :user-info-loading="userInfoLoading"
        :received-ref-money="receivedRefMoney"
        :count-used="countUsed"
        :total-account="totalAccount"
        :partner-bc-id="partnerBC"
        class="d-none d-md-flex"
      />

      <div class="d-flex align-items-start">
        <list-ads-account class="width-table" />
        <list-event-slide
          v-if="false"
          class="width-slide mt-2 mt-md-0"
        />
      </div>
    </div>

    <gift-code-notification-modal
      ref="open-modal-gift-code-notification"
      @hide-modal-confirm="hideModalGiftCode"
    />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import adsAccount from '@/mixins/adsAccount'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import envMixin from '@/mixins/envMixin'
// import SetupGuide from '@/components/setup-guide-component/SetupGuide.vue'
import BannerHeader from '@/views/home/components/BannerHeader.vue'
import partnerMixin from '@/mixins/partnerMixin'
import SummaryBalance from './components/Summary.vue'
import ListAdsAccount from './components/ListAdsAccount.vue'
import ListEventSlide from './components/ListEventSlide.vue'
import AdsBlockedNotice from './components/AdsBlockedNotice.vue'
import GiftCodeNotificationModal from './components/GiftCodeNotificationModal.vue'
import BannerAffiliateProgram from './components/BannerAffiliateProgram.vue'

const { mapGetters } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapActions: mapActionsGiftCode, mapGetters: mapGettersGiftCode } = createNamespacedHelpers('giftCode')
export default {
  components: {
    ListAdsAccount,
    SummaryBalance,
    ListEventSlide,

    AdsBlockedNotice,
    GiftCodeNotificationModal,
    BannerHeader,
    // SetupGuide,
    BannerAffiliateProgram,
  },

  mixins: [numberFormatMixin, adsAccount, stepGuideMixin, envMixin, partnerMixin],

  data() {
    return {
      isHideModalGiftCode: false,
    }
  },

  computed: {
    ...mapGetters([
      'listAccount',
      'message',
      'status',
      'loading',
    ]),
    ...mapGettersAuth(['user', 'userReports', 'generalConfigs']),
    ...mapGettersGiftCode(['listGiftCodePartner']),
    spentToday() {
      return this.amountFormat(
        _get(this.userReports, ['data', 'spentToday'], 0),
      )
    },

    totalSpending() {
      return this.amountFormat(
        _get(this.userReports, ['data', 'totalSpent'], 0),
      )
    },

    userReportLoading() {
      return _get(this.userReports, ['loading'], false)
    },

    totalUserBalance() {
      return _get(this.user, ['data', 'balance'], 0)
    },

    userInfoLoading() {
      return _get(this.user, ['loading'], false)
    },

    hasReferralTracking() {
      return _get(this.user, ['data', 'referralTracking'], {})
    },

    receivedRefMoney() {
      return _get(this.user, ['data', 'referralTracking', 'receivedMoney'], 0)
    },

    countUsed() {
      return _get(this.user, ['data', 'referralTracking', 'countUsed'], 0)
    },

    totalAccount() {
      return _get(this.listAccount, ['paging', 'total'], 0)
    },

    partnerBC() {
      return _get(this.listAccount, ['content', 0, 'partnerBCId'], '')
    },

    idPartner() {
      return _get(
        this.user,
        ['data', 'belongToPartner', 'partnerId', '_id'],
        '',
      )
    },

    isBanedBC() {
      return _get(this.user, ['data', 'alert', 'hasBanedBC'], false)
    },

    showAdsBlockNotice() {
      return false
      /* --- Open if needed --*/
      //  return this.isBanedBC && this.isEcomdyPlatform
    },
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo?.data?.belongToPartner) {
          this.fetchListGiftCodePartner()
        }
      },
      deep: true,
      immediate: true,
    },

    listGiftCodePartner: {
      handler(giftCode) {
        if (giftCode?.paging?.total > 0) {
          const giftCodeActive = giftCode?.content?.find(item => item?.status === 'active' && (item.quantity > item.savedQuantity))
          this.$nextTick(() => {
            if (giftCodeActive && !this.isHideModalGiftCode) {
              this.openNotificationGiftCodeModal()
            }
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.isHideModalGiftCode = JSON.parse(localStorage.getItem('isHideModalGiftCode'))
  },

  methods: {
    ...mapActionsGiftCode(['getListGiftCodePartner']),

    openNotificationGiftCodeModal() {
      this.$refs['open-modal-gift-code-notification'].showModal()
    },

    hideModalGiftCode() {
      this.isHideModalGiftCode = true
      localStorage.setItem('isHideModalGiftCode', JSON.stringify(this.isHideModalGiftCode))
    },

    async fetchListGiftCodePartner() {
      const params = {
        id: this.user?.data?.belongToPartner?.partnerId?._id,
        page: 1,
        size: 10,
      }

      await this.getListGiftCodePartner(params)
    },
  },
}
</script>

<style lang="scss" scoped>
  .warning-message {
    background: rgba(251, 176, 59, 0.1);
    border-radius: 10px;
    padding: 22px;
  }

  .width-table {
    //width: calc(100% - 344px);
    width: 100%;

    @media (max-width: 767px) {
    margin-right: 0;
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      //width: calc(100% - 294px);
      width: 100%;
    }
  }

  .width-slide {
    width: max-content;
    height: max-content;
    margin-left: 24px;

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      width: 270px;
    }
  }
</style>
